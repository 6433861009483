import service from "../utils/service.js"
//封装相关数据请求

let http = (url, method = "GET", data, params) => {
    return new Promise((resolve, reject) => {
        service.request({
            url,
            method,
            data,
            params
        }).then((success) => {
            resolve(success)
        }).catch((error) => {
            reject(error)
        })
    })
}

export default http