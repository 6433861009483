import axios from "axios"
import { ElMessage } from "element-plus"

let axiosurl = ""

if (process.env.NODE_ENV === "development") {
    axiosurl = process.env.VUE_APP_API
} else {
    axiosurl = process.env.VUE_APP_API
}

//创建axios实例
const service = axios.create({
    baseURL: '/proxy_url'
})

// 添加请求拦截器
service.interceptors.request.use(function (config) {
    if (config.url !== "/register") {
        // 统一设置接口认证
        const token = sessionStorage.getItem('token')
        if (token) {
            config.headers.token = token
        }
    }
    // 发送请求
    return config;
}, function (error) {
    return Promise.reject(error);
});

// 添加响应拦截器
service.interceptors.response.use(function (response) {
    if (response.data.token) {
        // 保存到本地存储：sessionStorage
        sessionStorage.setItem('token', response.data.token)
    }
    // 响应数据
    return response;
}, function (error) {
    let msg = ''
    switch (error.request.status) {
        case 401:
            msg = '请先登录'
            break;
        case 404:
            msg = 'URL地址错误'
            break;
        case 500:
            msg = '服务器错误'
            break;
        default:
            msg = '未知错误'
            break;
    }
    ElMessage({
        message: msg,
        type: 'error',
        duration: 1000
    })
    // 对响应错误做点什么
    return Promise.reject(error);
});

export default service
