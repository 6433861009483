import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 引入ElementPlus和其css文件
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import locale from 'element-plus/lib/locale/lang/zh-cn' //中文

import 'lib-flexible/flexible.js'
import print from 'vue3-print-nb'

import vue3videoPlay from "vue3-video-play"; // 引入组件
import "vue3-video-play/dist/style.css"; // 引入css
//使用
const app = createApp(App)
app.use(router)
app.use(store)
app.use(print)
app.use(ElementPlus, { locale })
app.use(vue3videoPlay)
app.mount('#app')