export function checkEmail (params) {
    let reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/
    return !reg.test(params) ? true : false
}

export function checkPass (params) {
    let reg = /^(?!\D+$)(?![^a-zA-Z]+$)\S{6,15}$/ //验证密码  6至15位的字母+数字
    return !reg.test(params) ? true : false
}

export function checkPhone (params) {
    let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
    return !reg.test(params) ? true : false
}

export function checkLnt (params) {
    let reg = /^[\-\+]?(0(\.\d{1,10})?|([1-9](\d)?)(\.\d{1,10})?|1[0-7]\d{1}(\.\d{1,10})?|180\.0{1,10}|180)$/
    return !reg.test(params) ? true : false
}

export function checkLat (params) {
    let reg = /^(\-|\+)?([0-8]?\d{1}\.\d{0,6}|90\.0{0,6}|[0-8]?\d{1}|90)$/
    return !reg.test(params) ? true : false
}

export function checkNumber (params) {
    let reg = /^([1-9]\d*\.?\d*)|(0\.\d*[1-9])|0$/
    return !reg.test(params) ? true : false
}
