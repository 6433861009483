import http from './http.js'

/**
 * 用户注册
 * @param params 
 * @returns 
 */
export function user_register(params: any) {
  return new Promise((resolve, reject) => {
    http(
      '/user/register',
      'POST',
      params,
      {}
    ).then((success:any) => {
      resolve(success.data)
    }).catch((error:any) => {
      reject(error)
    })
  })
}
/**
 * 用户登录
 * @param params 
 * @returns 
 */
export function user_login(params: any) {
  return new Promise((resolve, reject) => {
    http(
      '/user/login',
      'POST',
      params,
      {}
    ).then((success:any) => {
      resolve(success.data)
    }).catch((error:any) => {
      reject(error)
    })
  })
}
/**
 * 用户修改
 * @param params 
 * @returns 
 */
export function user_edit(params: any) {
  return new Promise((resolve, reject) => {
    http(
      '/user/edit',
      'POST',
      params,
      {}
    ).then((success:any) => {
      resolve(success.data)
    }).catch((error:any) => {
      reject(error)
    })
  })
}
/**
 * 用户修改密码
 * @param params 
 * @returns 
 */
export function user_change_password(params: any) {
  return new Promise((resolve, reject) => {
    http(
      '/user/changePassword',
      'POST',
      params,
      {}
    ).then((success:any) => {
      resolve(success.data)
    }).catch((error:any) => {
      reject(error)
    })
  })
}
/**
 * 获取用户信息
 * @param params 
 * @returns 
 */
export function get_user_info(params: any) {
  return new Promise((resolve, reject) => {
    http(
      '/user/get_user_info',
      'POST',
      params,
      {}
    ).then((success:any) => {
      resolve(success.data)
    }).catch((error:any) => {
      reject(error)
    })
  })
}
/**
 * 获取用户信息
 * @param params 
 * @returns 
 */
export function get_user_group(params: any) {
  return new Promise((resolve, reject) => {
    http(
      '/user/group/queryGroup',
      'POST',
      params,
      {}
    ).then((success:any) => {
      resolve(success.data)
    }).catch((error:any) => {
      reject(error)
    })
  })
}
/**
 * 发送验证码
 * @param params 
 * @returns 
 */
export function send_code(params: any) {
  return new Promise((resolve, reject) => {
    http(
      '/sms/sendCodeWeb',
      'POST',
      params,
      {}
    ).then((success:any) => {
      resolve(success.data)
    }).catch((error:any) => {
      reject(error)
    })
  }) 
}
/**
 * 校验验证码
 * @param params 
 * @returns 
 */
export function valid_code(params: any) {
  return new Promise((resolve, reject) => {
    http(
      '/sms/validCode',
      'POST',
      params,
      {}
    ).then((success:any) => {
      resolve(success.data)
    }).catch((error:any) => {
      reject(error)
    })
  }) 
}
/**
 * 轮播图列表  
 * @returns 
 */
export function loop_play_list() {
  return new Promise((resolve, reject) => {
    http(
      '/looplay/play_list',
      'GET',
      {},
      {}
    ).then((success:any) => {
      resolve(success.data)
    }).catch((error:any) => {
      reject(error)
    })
  }) 
}
/**
 * 推荐课程列表  
 * @param params
 * @returns 
 */
export function course_top_list(params:any) {
  return new Promise((resolve, reject) => {
    http(
      '/course/topList',
      'POST',
      params,
      {}
    ).then((success:any) => {
      resolve(success.data)
    }).catch((error:any) => {
      reject(error)
    })
  }) 
}
/**
 * 获取课程列表
 * @param params 
 * @returns 
 */
export function get_course_list(params: any) {
  return new Promise((resolve, reject) => {
    http(
      '/course/appList',
      'POST',
      params,
      {}
    ).then((success:any) => {
      resolve(success.data)
    }).catch((error:any) => {
      reject(error)
    })
  })
}
/**
 * 获取课程详情
 * @param params 
 * @returns 
 */
export function get_course_info(params: any) {
  return new Promise((resolve, reject) => {
    http(
      '/course/getById',
      'POST',
      params,
      {}
    ).then((success:any) => {
      resolve(success.data)
    }).catch((error:any) => {
      reject(error)
    })
  }) 
}
/**
 * 创建订单
 * @param params 
 * @returns 
 */
export function create_order(params: any) {
  return new Promise((resolve, reject) => {
    http(
      '/pay/web/createOrder',
      'POST',
      params,
      {}
    ).then((success:any) => {
      resolve(success.data)
    }).catch((error:any) => {
      reject(error)
    })
  }) 
}
/**
 * 查询订单状态
 * @param params 
 * @returns 
 */
export function get_order_status(params: any) {
  return new Promise((resolve, reject) => {
    http(
      '/pay/queryClassId',
      'POST',
      params,
      {}
    ).then((success:any) => {
      resolve(success.data)
    }).catch((error:any) => {
      reject(error)
    })
  }) 
}
/**
 * 获取专家团队
 * @param params 
 * @returns 
 */
export function expert_team(params: any) {
  return new Promise((resolve, reject) => {
    http(
      '/lecturer/typeList',
      'POST',
      params,
      {}
    ).then((success:any) => {
      resolve(success.data)
    }).catch((error:any) => {
      reject(error)
    })
  }) 
}
/**
 * 分页获取动态列表
 * @param params 
 * @returns 
 */
export function page_news_list(params: any) {
  return new Promise((resolve, reject) => {
    http(
      '/latestNews/list',
      'POST',
      params,
      {}
    ).then((success:any) => {
      resolve(success.data)
    }).catch((error:any) => {
      reject(error)
    })
  }) 
}
/**
 * 获取动态列表
 * @param params 
 * @returns 
 */
export function get_news_list(params: any) {
  return new Promise((resolve, reject) => {
    http(
      '/latestNews/playList',
      'POST',
      params,
      {}
    ).then((success:any) => {
      resolve(success.data)
    }).catch((error:any) => {
      reject(error)
    })
  }) 
}
/**
 * 图片上传
 * @param params 
 * @returns 
 */
export function upload_image(params:any) {
  return new Promise((resolve, reject) => {
    http(
      '/upload/image',
      'POST',
      params,
      {}
    ).then((success:any) => {
      resolve(success.data)
    }).catch((error:any) => {
      reject(error)
    })
  })
}
/**
 * 获取线上培训列表
 * @param params 
 * @returns 
 */
export function get_ontrain_list(params: any) {
  return new Promise((resolve, reject) => {
    http(
      '/onlineTrain/app/list',
      'POST',
      params,
      {}
    ).then((success:any) => {
      resolve(success.data)
    }).catch((error:any) => {
      reject(error)
    })
  }) 
}
/**
 * 获取线下培训列表
 * @param params 
 * @returns 
 */
export function get_offtrain_list(params: any) {
  return new Promise((resolve, reject) => {
    http(
      'trainClass/app/list',
      'POST',
      params,
      {}
    ).then((success:any) => {
      resolve(success.data)
    }).catch((error:any) => {
      reject(error)
    })
  }) 
}
/**
 * 创建试卷
 * @param params 
 * @returns 
 */
export function question_build(params: any) {
  return new Promise((resolve, reject) => {
    http(
      '/test/examBuild',
      'POST',
      params,
      {}
    ).then((success:any) => {
      resolve(success.data)
    }).catch((error:any) => {
      reject(error)
    })
  }) 
}
/**
 * 获取答案列表
 * @param params 
 * @returns 
 */
export function get_answer_list(params: any) {
  return new Promise((resolve, reject) => {
    http(
      '/question/answerAppList',
      'POST',
      params,
      {}
    ).then((success:any) => {
      resolve(success.data)
    }).catch((error:any) => {
      reject(error)
    })
  }) 
}
/**
 * 提交答案
 * @param params 
 * @returns 
 */
export function submit_answer(params: any) {
  return new Promise((resolve, reject) => {
    http(
      '/test/examAnswer',
      'POST',
      params,
      {}
    ).then((success:any) => {
      resolve(success.data)
    }).catch((error:any) => {
      reject(error)
    })
  }) 
}
/**
 * 获取课程播放记录
 * @param params 
 * @returns 
 */
export function get_play_by_course(params: any) {
  return new Promise((resolve, reject) => {
    http(
      '/play/app/queryId',
      'POST',
      params,
      {}
    ).then((success:any) => {
      resolve(success.data)
    }).catch((error:any) => {
      reject(error)
    })
  }) 
}
/**
 * 增加播放记录
 * @param params 
 * @returns 
 */
export function add_play_record(params: any) {
  return new Promise((resolve, reject) => {
    http(
      '/play/add',
      'POST',
      params,
      {}
    ).then((success:any) => {
      resolve(success.data)
    }).catch((error:any) => {
      reject(error)
    })
  }) 
}
/**
 * 增加学时记录
 * @param params 
 * @returns 
 */
export function add_study_hours(params: any) {
  return new Promise((resolve, reject) => {
    http(
      '/study/add',
      'POST',
      params,
      {}
    ).then((success:any) => {
      resolve(success.data)
    }).catch((error:any) => {
      reject(error)
    })
  }) 
}
/**
 * 获取播放记录
 * @param params 
 * @returns 
 */
export function get_play_list(params: any) {
  return new Promise((resolve, reject) => {
    http(
      '/play/app/list',
      'POST',
      params,
      {}
    ).then((success:any) => {
      resolve(success.data)
    }).catch((error:any) => {
      reject(error)
    })
  }) 
}
/**
 * 获取学时记录
 * @param params 
 * @returns 
 */
export function get_study_list(params: any) {
  return new Promise((resolve, reject) => {
    http(
      '/study/userStat',
      'POST',
      params,
      {}
    ).then((success:any) => {
      resolve(success.data)
    }).catch((error:any) => {
      reject(error)
    })
  }) 
}
/**
 * 获取购买记录
 * @param params 
 * @returns 
 */
export function get_pay_list(params: any) {
  return new Promise((resolve, reject) => {
    http(
      '/pay/app/list',
      'POST',
      params,
      {}
    ).then((success:any) => {
      resolve(success.data)
    }).catch((error:any) => {
      reject(error)
    })
  }) 
}
/**
 * 增加定制培训需求调查
 * @param params 
 * @returns 
 */
export function add_train_surveys(params: any) {
  return new Promise((resolve, reject) => {
    http(
      '/trainSurveys/add',
      'POST',
      params,
      {}
    ).then((success:any) => {
      resolve(success.data)
    }).catch((error:any) => {
      reject(error)
    })
  }) 
}
/**
 * 同步用户信息给一路学网
 * @returns 
 */
export function learn_sync_user() {
  return new Promise((resolve, reject) => {
    http(
      '/learn/syncUser',
      'GET',
      {},
      {}
    ).then((success:any) => {
      resolve(success.data)
    }).catch((error:any) => {
      reject(error)
    })
  }) 
}
/**
 * 获取一路学网学习进度
 * @returns 
 */
export function learn_get_progress() {
  return new Promise((resolve, reject) => {
    http(
      '/learn/progress',
      'GET',
      {},
      {}
    ).then((success:any) => {
      resolve(success.data)
    }).catch((error:any) => {
      reject(error)
    })
  }) 
}
/**
 * 获取一路学网购买记录
 * @returns 
 */
export function learn_get_order() {
  return new Promise((resolve, reject) => {
    http(
      '/learn/getOrder',
      'GET',
      {},
      {}
    ).then((success:any) => {
      resolve(success.data)
    }).catch((error:any) => {
      reject(error)
    })
  }) 
}
/**
 * 获取一路学网购买记录
 * @param params
 * @returns 
 */
export function get_train_class(params:any) {
  return new Promise((resolve, reject) => {
    http(
      'trainClass/app/queryId',
      'POST',
      params,
      {}
    ).then((success:any) => {
      resolve(success.data)
    }).catch((error:any) => {
      reject(error)
    })
  }) 
}
/**
 * 获取用户年度学习列表
 * @param params
 * @returns 
 */
export function study_year_list(params:any) {
  return new Promise((resolve, reject) => {
    http(
      '/study/yearList',
      'POST',
      params,
      {}
    ).then((success:any) => {
      resolve(success.data)
    }).catch((error:any) => {
      reject(error)
    })
  }) 
}
/**
 * 获取证书PDF
 * @param params
 * @returns 
 */
export function get_cert_pdf(params:any) {
  return new Promise((resolve, reject) => {
    http(
      '/pdf/cert',
      'POST',
      params,
      {}
    ).then((success:any) => {
      resolve(success.data)
    }).catch((error:any) => {
      reject(error)
    })
  }) 
}
/**
 * 获取学时记录(线上)
 * @param params 
 * @returns 
 */
export function get_user_cert(params: any) {
  return new Promise((resolve, reject) => {
    http(
      '/study/userCert',
      'POST',
      params,
      {}
    ).then((success:any) => {
      resolve(success.data)
    }).catch((error:any) => {
      reject(error)
    })
  }) 
}
/**
 * 获取学时记录(线下)
 * @param params 
 * @returns 
 */
export function get_user_offline_cert(params: any) {
  return new Promise((resolve, reject) => {
    http(
      '/offlineCert/getByIdCard',
      'POST',
      params,
      {}
    ).then((success:any) => {
      resolve(success.data)
    }).catch((error:any) => {
      reject(error)
    })
  }) 
}
/**
 * 获取建设厅学时记录(线下)
 * @param params 
 * @returns 
 */
export function get_jst_offline_cert(params: any) {
  return new Promise((resolve, reject) => {
    http(
      '/offlineCert/getJstByIdCard',
      'POST',
      params,
      {}
    ).then((success:any) => {
      resolve(success.data)
    }).catch((error:any) => {
      reject(error)
    })
  }) 
}