const HomeModule: Object = {
  state: {
    loginRegisterDialogShow: true
  },
  mutations: {// 修改state的数据
    SET_LOGIN_REGISTER(state: any) {
      state.loginRegisterDialogShow = !state.loginRegisterDialogShow
    },
  },
  actions: {

  },
  getters: {

  }
}

export default HomeModule