import jsCookie from "js-cookie"

export function setCookie (params:any) {
    jsCookie.set('LOGIN_LOCAL_KEY', JSON.stringify(params), {expires: 7})
}

export function getCookie () {
    const userInfo = jsCookie.get('LOGIN_LOCAL_KEY')
    return userInfo
}

export function setCookieCourse (params:any) {
    jsCookie.set('COURSE_LOCAL_KEY', JSON.stringify(params), {expires: 7})
}

export function getCookieCourse () {
    const info = jsCookie.get('COURSE_LOCAL_KEY')
    return info
}

export function removeCookie () {
    jsCookie.remove('LOGIN_LOCAL_KEY')
    jsCookie.remove('COURSE_LOCAL_KEY')
}