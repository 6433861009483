/**
 * 配置文件
 * @author huazai
 */
const config = {
  "copyright"  :  "Copyright@2020",
  "icp_number" :  "黔ICP备16009911号-5",
  "address"    :  "贵阳市南明区宝山南路27号东山大厦1005",
  "e_mail"     :  "gzcept@qq.com",
  "tel"        :  "0851-85584260",
  "t_support"  :  "贵州省水利水电勘测设计研究院有限公司高新设计院智能信息化所",
  "tel_support":  "0851-85584833",
  "icp_url"    :  "https://beian.miit.gov.cn",
  "try_time"   : 120, //试看时长2分钟，120秒
  "cert_title" : "专业技术人员继续教育"
}

export default config