import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/Layout.vue'


const routes: Array<RouteRecordRaw> = [
  {
  path: '/',
  component: Layout,
  children: [
    {
      path: '',
      name: 'home',
      component: () => import('@/views/Home/index.vue')
    },
    {
      path: '/course',
      name: 'course',
      component: () => import('@/views/Course/index.vue')      
    },
    {
      path: '/news',
      name: 'news',
      component: () => import('@/views/News/index.vue')      
    },    
    {
      path: '/customized',
      name: 'customized',
      component: () => import('@/views/Customized/index.vue')      
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('@/views/About/index.vue')      
    },
    {
      path: '/video',
      name: 'video',
      component: () => import('@/views/Course/Video.vue')      
    },
    {
      path: '/center',
      name: 'center',
      component: () => import('@/views/Center/index.vue'),
      meta: {
        hidNavFoo: true
      }
    },
    {
      path: '/courseInfo',
      name: 'courseInfo',
      component: () => import('@/views/Course/Info.vue')
    },
    {
      path: '/newsInfo',
      name: 'newsInfo',
      component: () => import('@/views/News/Info.vue')
    },
    {
      path: '/online',
      name: 'online',
      component: () => import('@/views/Training/Online.vue')      
    },
    {
      path: '/offline',
      name: 'offline',
      component: () => import('@/views/Training/Offline.vue')      
    },
    {
      path: '/trainInfo',
      name: 'trainInfo',
      component: () => import('@/views/Training/Info.vue')      
    },
    {
      path: '/exam',
      name: 'exam',
      component: () => import('@/views/Exam/index.vue')
    },
    {
      path: '/cert',
      name: 'cert',
      component: () => import('@/views/Cert/index.vue'),
      meta: {
        hidNavFoo: true,
        fullScreen: true
      }
    },
    {
      path: '/certquery',
      name: 'certquery',
      component: () => import('@/views/Cert/Query.vue')
    },
  ]
}
]

const router = createRouter({
  // 路由模式
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
