/**
 * 根据身份证号码获取出生日期
 * @param idno 
 * @returns 
 */
export function getBirthday(idno: string) {
  let birthday = ''
  if (idno.length == 18) {
    birthday = idno.substring(6, 14)
    birthday = birthday.replace(/(.{4})(.{2})/, "$1-$2-")
  } else if (idno.length == 15) {
    birthday = '19' + idno.substring(6, 12);
    birthday = birthday.replace(/(.{4})(.{2})/, "$1-$2-")
  }
  return birthday
}

/**
 * 根据身份证号码获取性别
 * @param idno 
 * @returns 
 */
export function getSex(idno: string) {
  let sex = 0
  if (idno.length == 18) {
    sex = parseInt(idno.charAt(16)) % 2 == 0 ? 2 : 1
  } else if (idno.length == 15) {
    sex = parseInt(idno.charAt(14)) % 2 == 0 ? 2 : 1
  }
  return sex
}